
import {computed, defineComponent, onMounted, ref} from "vue";
import {getCurrentSpaceAccess, GetSpaceId, LoadPanel, PinaFilterObjects} from "@/core/composite/composite";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import WorkForm from "@/views/work/WorkForm.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import UserLink from "@/views/user/UserLink.vue";
import Swal from "sweetalert2";
import WorkService from "@/core/services/WorkService";
import SearchHorizontal from "@/components/common/SearchHorizontal.vue";
import {useTaskStore} from "@/store/task.store";
import TaskCompleteStatus from "@/views/task/TaskCompleteStatus.vue";
import TaskDueDate from "@/views/work/TaskDueDate.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import TaskName from "@/views/task/TaskName.vue";
import {useQuickActionStore} from "@/store/quick.action.store";
import HelpEmbed from "@/components/base/HelpEmbed.vue";

export default defineComponent({
  name: "List",
  components: {
    HelpEmbed,
    TaskName,
    DateTimeFormat,
    TaskDueDate,
    TaskCompleteStatus,
    SearchHorizontal,
    UserLink, UserListSelect, WorkForm, BaseModal, QuickAction, KtDatatable
  },
  setup() {
    const createWorkRef = ref<any>(null);
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Work",
        [
          {link: false, router: '', text: 'Work'}
        ]
      )
    })
    const callback = (action) => {
      if (action.args[0] === 'WORK') {
        createWorkRef?.value?.click();
      }
    }
    const quickActionStore = useQuickActionStore();
    quickActionStore.$onAction(callback, true)

    const headers = ref<any>([])
    const buildHeaders = (isCompleted: boolean) => {
      if (isCompleted) {
        headers.value = [
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'Status',
            key: 'status',
            sortable: false,
          },
          {
            name: 'Assigned',
            key: 'assigned',
            sortable: false
          },
          {
            name: 'Due Date',
            key: 'dueDate',
            sortable: false
          },
          {
            name: 'Completed',
            key: 'completedDt',
            sortable: false
          },
        ]
      } else {
        headers.value = [
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'Assigned',
            key: 'assigned',
            sortable: false
          },
          {
            name: 'Due Date',
            key: 'dueDate',
            sortable: false
          },
        ]
      }
    }
    buildHeaders(false);
    const taskStore = useTaskStore();
    const objectFilter = ref({
      isDelegateTask: false,
      completed: false,
      overdue: false,
      noComplete: true,
      owner: false,
      assignedId: '',
      showArchived: false
    })
    const page = computed(() => taskStore.page);
    const state = ref({
        name: 'Create Work',
        work: {
          name: '',
          assignedIdRequest: '',
          schedule: {period: 'DAILY', frequencies: []},
          description: '',
          docIdRequest: '',
        },
        workId: ''
      }
    )
    return {
      state,
      objectFilter,
      createWorkRef,
      buildHeaders,
      ...PinaFilterObjects(taskStore, objectFilter.value, ['owner'], 'sort:dueDate:asc'),
      headers,
      page,
      ...LoadPanel(),
      ...getCurrentSpaceAccess(),
      ...GetSpaceId(),
    }
  },
  methods: {
    searchEvent() {
      this.objectFilter.noComplete = !this.objectFilter.completed;
      if (this.objectFilter.completed) {
        this.buildHeaders(true)
        this.updateSort('sort:dueDate:desc')
      } else {
        this.buildHeaders(false)
        this.updateSort('sort:dueDate:asc')
      }
      this.updateFilterObject(this.objectFilter);
    },

    discardEvent() {
      this.updateFilterObject(this.objectFilter);
    },
    add() {
      this.state.workId = '';
      this.state.work = {
        name: '',
        assignedIdRequest: '',
        schedule: {period: 'DAILY', frequencies: []},
        description: '',
        docIdRequest: '',
      }
      this.state.name = 'Create Work'
      const modal = this.$refs.workFormRef as typeof BaseModal
      modal.showBaseModal();
    },
    onEdit(entity) {
      this.state.work = {
        name: entity.name,
        assignedIdRequest: entity?.assigned?.id,
        schedule: {period: entity.schedule.period, frequencies: entity.schedule.frequencies},
        description: entity.description,
        docIdRequest: entity.docId
      }
      this.state.workId = entity.id;
      this.state.name = 'Edit Work'
      const modal = this.$refs.workFormRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onUpdateStatus(id, status) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      WorkService.updateStatus(id, status).then(() => {
        this.paginationDataLoad()
      }).finally(() => {
        Swal.close();
      })
    },
    onSaved() {
      const modal = this.$refs.workFormRef as typeof BaseModal
      modal.hideBaseModal();
      this.paginationDataLoad()
    }
  }
})
